import React, { Component } from 'react';
//import { hot } from 'react-hot-loader/root';
import MapView from './components/map';
import Routes from './static/routes';
import NadeView from './components/nadeView';
//import "./scss/style.scss";
import {
  //Router,
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";





import {
  EuiPageTemplate,
  EuiErrorBoundary
} from '@elastic/eui';
const PiwikReactRouter = require('piwik-react-router');
import { createBrowserHistory } from "history";
 
const piwik = PiwikReactRouter({
    url: 'stats.stratbuff.io',
    siteId: 1,
    trackErrors: true,
    clientTrackerName: 'statsJ',
    serverTrackerName: 'statsP'
});




class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let history = createBrowserHistory();
    return (

      <Router history={piwik.connectToHistory(history)}>
        
        <EuiErrorBoundary>

          <EuiPageTemplate
      restrictWidth
      template="empty"

      pageHeader={{
        iconType: 'logoElastic',
        pageTitle: 'stratbuff.io',
        rightSideItems: [<Link key="nades" to="/nades/de_mirage/SMOKE">Nades</Link>],
      }}>

            <Route path={Routes.NADES} component={MapView} />
            <Route path={Routes.NADE} component={NadeView} />
          </EuiPageTemplate>
        </EuiErrorBoundary>
      </Router>

);
      

  }
}
export default App;
