import React from 'react';
import PropTypes from 'prop-types';
import style from './visualizer.css';
//import * as data from '../../static/nades/nades.json';

import MapBackground from '../mapBackground'

import MapItem from '../mapItem';


class Visualizer extends React.Component {
  constructor(props) {
    super(props);
    //this.displayAll();
  }
  componentDidUpdate(prevProps) {
    /*if (prevProps.map !== this.props.map || prevProps.type !== this.props.type ) {
      this.setState({nades : nades[this.props.map][this.props.type], starts: []})
    }
    console.dir(this.state) */
  }


  displayAll = event => {
    this.props.setSelectionState({clusterId:-1, nadeId: -1});
    //this.setState({nades : nades[this.props.map][this.props.type], starts: []})
  }
  handleStartSelected = (e, start) => {
    e.stopPropagation();
    console.log("selected "+start);
    console.dir(start)
    this.props.setSelectionState({clusterId:start.id});
    //this.setState({nades : {"0":start}, starts: start.nades})
  }
  handleTargetSelect = (e, target) => {
    console.dir(target);
    this.props.setSelectionState({nadeId:target.id});
    e.stopPropagation();
  }
  handleMapSelect = target => {
    this.displayAll();
  }



  render() {
    return (
      <div className={style.visualizer} onClick={() => this.handleMapSelect()}>
        <div className={style.nadeLayer}>
          {Object.entries(this.props.clusters).map(([key, nade]) => {
           // Return the element. Also pass key
           return (<MapItem key={nade.id} nade={nade} pos={nade.npos} type={nade.type} onClick={(e) => this.handleStartSelected(e, nade)} />);
        })}
          {Object.entries(this.props.nades).map(([key, start]) => {
           // Return the element. Also pass key
           return (<MapItem key={start.id} nade={start} pos={start.tpos} type="START" onClick={(e) => this.handleTargetSelect(e,start)} />);
        })}
        </div>
        <MapBackground map={this.props.map} />
      </div>
      );
  }
}
/*  */

export default Visualizer;
