class Maps{
    constructor(map) {
      this.map = map;
    }

    data = {
        "de_cache":{
             pZeroX:-2000,pZeroY: 3250,scale: 5.5
        },
        "de_canals":{
            pZeroX:-2496,pZeroY: 1792,scale: 4
       },
       "de_cbble":{
            pZeroX:-3840,pZeroY: 3072,scale: 6
        },
        "de_dust2":{
            pZeroX:-2476,pZeroY: 3239,scale: 4.4
        },
        "de_inferno":{
            pZeroX:-2087,pZeroY: 3870,scale: 4.9
        },
        "de_mirage":{
            pZeroX:-3230,pZeroY: 1713,scale: 5
        },
        "de_nuke":{
            pZeroX:-3453,pZeroY: 2887,scale: 7
        },
        "de_overpass":{
            pZeroX:-4831,pZeroY: 1781,scale: 5.2
        },
        "de_train":{
            pZeroX:-2477,pZeroY: 2392,scale: 4.7
        },
        "de_vertigo":{
            pZeroX:-3168,pZeroY: 1762,scale: 4
        },
    }
    translateToPercent(x,y, map){
        console.log(this.map);
        let d = this.data[map];
        let map_x = ((x - d.pZeroX) / d.scale) / 1024 * 100;
        let map_y = ((y - d.pZeroY) / d.scale) / 1024 * 100;
        return [map_x,map_y]
    }
    setMap(map){
        this.map = map;
    }
    path(){
        return this.path(this.map)
    }
    path(map){
        return "/static/maps/overviews"+map+".jpg";
    }

  }

  export default Maps;