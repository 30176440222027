import React, { Fragment }  from 'react';
import style from './visualizer.css';
import PositionImage from '../positionImages'
import CopyField from '../copyField'
import SimpleTable from '../simpleTable'
import Routes from './../../static/routes';
import * as data from '../../../../static/clustered/nades_live.json';

import {
  EuiIcon,
  EuiTabbedContent,
  EuiTitle,
  EuiText,
  EuiSpacer,
  EuiButton,
  EuiPortal,
  EuiPanel,
  EuiToolTip,
  EuiCopy,
  EuiFieldText,
  EuiButtonIcon
} from '@elastic/eui';
import { EuiStat, EuiFlexItem, EuiFlexGroup } from '@elastic/eui';
import { format } from 'prettier';

function NumberFormatter(props){
  return <span>{Math.round(props.children*100)}</span>
}
function AirtimeFormatter(props){
  return <span>{Number(props.children).toFixed(2)}{props.append}s</span>
}
const capitalizeFirstLetter = ([ first, ...rest ], locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join('')

function ThrowTypeString(props){
  let nade = props.nade.mov;
  let name = "";
  if(nade.isRunning){
    name = name + "running";
  }
  if(nade.isCrouching){
    name = name + "crouching";
  }
  if(nade.isWalking){
    name = name + "walking";
  }
  if(nade.isJumping){
    name = name + "jump";
  }
  name = name + "throw";
  return capitalizeFirstLetter(name);
}
function nadeToCommand(nade){
  let c=4;
  return ('setpos ' + Number(nade.tpos.x).toFixed(c) + ' '+ Number(nade.tpos.y).toFixed(c) + ' '+ Number(nade.tpos.z).toFixed(c) + '; setang '+ Number(nade.dir.p).toFixed(c) + ' '+ Number(nade.dir.y).toFixed(c) + ';');
}



class NadeView extends React.Component {
  constructor(props) {
    super(props);
  }
  teams = data.default.teams;
  players = data.default.names;
  

  tabs = [
    {
      id: 'gif',
      name: 'Video',
      content: (
        <Fragment>
          <div style={{position: 'relative', paddingBottom:'calc(56.25% + 44px)'}}><iframe src={"https://gfycat.com/ifr/"+this.props.nade.gif+"?hd=1"} frameBorder='0' scrolling='no' width='100%' height='100%' style={{position:'absolute',top:0,left:0}} allowFullScreen></iframe></div>
        </Fragment>
      ),
    },
    {
      id: 'lineup',
      name: 'Lineup',
      content: (
        <Fragment>
          <PositionImage nade={this.props.nade} view={"throw"}/>
        </Fragment>
      ),
    },
  ];

  closeModal = () => {};


  render() {
    return (
      <div>
        <EuiSpacer />
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiPanel>
              <EuiToolTip position="bottom" content="Works on anything" display="block">
                <EuiStat title={<ThrowTypeString nade={this.props.nade} />} description="Movement Mechanic" titleColor="primary" />
              </EuiToolTip>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel>
              <EuiStat title={<AirtimeFormatter>{this.props.nade.stats.airtime}</AirtimeFormatter>} description="Airtime" />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />
        <EuiPanel>
          <EuiTabbedContent
          tabs={this.tabs}
          initialSelectedTab={this.tabs[0]}
          autoFocus="selected"
          onTabClick={(tab) => {
            console.log('clicked tab', tab);
          }}/>
        </EuiPanel>

        <EuiSpacer />
        <EuiPanel>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiStat title={<NumberFormatter append="%">{this.props.nade.stats.offensiveUsage}</NumberFormatter>}  titleSize="m" description="Usage" />
              <EuiText  size="xs">Offensive</EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiStat title={<NumberFormatter append="%">{this.props.nade.stats.usage}</NumberFormatter>}  titleSize="m" description="Used By" titleColor="subdued" />
              <EuiText  size="xs">CT</EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiStat title={<NumberFormatter append="%">{this.props.nade.stats.std}</NumberFormatter>}  titleSize="m" description="Accuracy" titleColor="primary" />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiStat title={<NumberFormatter append="%">{this.props.nade.stats.usage}</NumberFormatter>} titleSize="m" description="Popularity" titleColor="primary" />
            </EuiFlexItem>
            
          </EuiFlexGroup>
        </EuiPanel>

        <EuiSpacer />
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiPanel>
              <EuiText size="s">
                <p>Ingame Position</p>
              </EuiText>
              <EuiSpacer size="s" />
              <CopyField value={nadeToCommand(this.props.nade)} tooltip="Paste this command to your console while connected to a server with the map loaded and sv_cheats enabled" />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel>
              <EuiText size="s">
                <p>Nade Link</p>
              </EuiText>
              <EuiSpacer size="s" />
              <CopyField value={Routes.nadeToLink(this.props.nade)} tooltip="Use this link to share or save this nade" />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiPanel>
              <SimpleTable strings={this.teams} items={this.props.nade.teams} title="Teams" />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel>
              <SimpleTable strings={this.players} items={this.props.nade.names} title="Players" />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
      );
  }
}
/*  */

export default NadeView;
