import React from 'react';
import PropTypes from 'prop-types';
import style from './positionImage.css';
import Routes from  '../../static/routes'



import {

  EuiFlyoutBody,
  EuiFlyout,
} from '@elastic/eui';
import { EuiSideNav, EuiAspectRatio } from '@elastic/eui';
import NadeView from '../nadeView';
const importAll = require.context ('../../../../static/screenshots/', true, /\.jpg$/ )
const images = importAll
  .keys ()
  .reduce ( ( images, path ) => {
    let paths = path.split('/')
    if(images[paths[1]] == undefined){
      images[paths[1]] = {};
    }
    images[paths[1]][paths[2].slice(0,-4)] = importAll ( path )
    return images
  }, {} )


//props: nade, view ∈ ["lineup", "throw"]
class PositionImages extends React.Component {
  constructor(props) {
    super(props);
    console.dir(images);
    //const [tabIndex, setTabIndex] = React.useState(0)
  }



  render() {

    return (
      <EuiAspectRatio width={16} height={9}>
        <div style={{position: "relative", overflow: "hidden"}}>
          <div className={style.targetZoom}></div>
          <div className={style.targetGround}></div>
          <div className={style.image+" "+style.imageNormal} style={{backgroundImage: "url("+images[this.props.nade.id][this.props.view].default+")"}}></div>
          <div className={style.image+" "+style.imageZoom+" "+style.imageOverlay} style={{backgroundImage: "url("+images[this.props.nade.id][this.props.view+"zoomed"].default+")"}}></div>
          <div className={style.image+" "+style.imageGround+" "+style.imageOverlay} style={{backgroundImage: "url("+images[this.props.nade.id][this.props.view+"ground"].default+")"}}></div>

        </div>
      </EuiAspectRatio>);
  }
}
/**/
/* require("images/img.svg") */


export default PositionImages;
