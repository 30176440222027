class Routes{
    static get HOST(){
        return window.location.hostname
    }
    static get NADES () {
        return "/nades/:map/:type/:clusterId?/:nadeId?";
    }
    static get NADE () {
        return "/nade";
    }

    static createNadePath(map, type, clusterId =-1, nadeId = -1){
        if(clusterId === -1 || nadeId === -1) return `/nades/${map}/${type}`;
        return `/nades/${map}/${type}/${clusterId}/${nadeId}`;
    }
    static set(that, route){
        console.dir(that);
        that.props.history.replace({ pathname: route});
    }
    static nadeToLink(nade){
        return Routes.HOST + Routes.createNadePath(nade.map, nade.type, nade.pid, nade.id);
      }
}

export default Routes;