import React from 'react';
import style from './mapBackground.css';



const importAll = require.context ('./overviews/', true, /\.jpg$/ )
const images = importAll
  .keys ()
  .reduce ( ( images, path ) => {
    images[path.slice(2,-4)] = importAll ( path )
    return images
  }, {} )


class MapBackground extends React.Component {
  constructor(props) {
    super(props);
    console.dir(images)
  }

  render() {
    
    return (
      <img  className={style.mapBackground} src={images[this.props.map].default}></img>
      );
  }
}

export default MapBackground;
