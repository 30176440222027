
import React, { useState } from 'react';

import {
  EuiBasicTable,
  EuiHealth,
  EuiIcon,
  EuiLink,
  EuiToolTip,
} from '@elastic/eui';




const SimpleTable = (props) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortField, setSortField] = useState('item');
  const [sortDirection, setSortDirection] = useState('asc');
  const data = props.items.map(x => {
    return {"name":props.strings[x]}
  })
  //data = data.sort();

  const onTableChange = ({ page = {}, sort = {} }) => {
    const { index: pageIndex, size: pageSize } = page;

    const { field: sortField, direction: sortDirection } = sort;

    setPageIndex(pageIndex);
    setPageSize(pageSize);
    setSortField(sortField);
    setSortDirection(sortDirection);

  };
  
  const getData = function(pageIndex, pageSize, sortField, sortDirection){
    console.log('hey')
    let d = data;
    if(sortDirection == 'desc'){
      d = d.reverse();
    }
    return {pageOfItems: d.slice(pageIndex*pageSize, (pageIndex+1)*pageSize), totalItemCount: d.length }
  }


  const { pageOfItems, totalItemCount } = getData(pageIndex,
    pageSize,
    sortField,
    sortDirection);



  const columns = [
    {
      field: 'name',
      name: props.title,
      sortable: true,
      truncateText: true,
      

    },
  ];

  const pagination = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    totalItemCount: props.items.length,
    pageSizeOptions: [10],
    hidePerPageOptions: true
  };

  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  };

  return (
    <div>
      <EuiBasicTable
        items={pageOfItems}
        columns={columns}
        pagination={pagination}
        sorting={sorting}
        onChange={onTableChange}/>
    </div>
  );
};
export default SimpleTable;