import React from 'react';
import PropTypes from 'prop-types';
import style from './filters.css';
import { EuiAccordion, EuiText, EuiRadioGroup,EuiDualRange,EuiButtonGroup } from '@elastic/eui';


class Filters extends React.Component {
  constructor(props) {
    super(props);
  }

  onSetMap = event => {
    this.props.setFilterState({ map: event });

  };

  onSetType = event => {
    this.props.setFilterState({ type: event });
  };
  onSetAirtime = event => {
    this.props.setFilterState({ airtime: event });
  };
  onSetSide = event => {
    this.props.setFilterState({ side: event });
  };

  maps = [
    {
      id: `de_dust2`,
      label: 'de_dust2',
    },
    {
      id: `de_mirage`,
      label: 'de_mirage',
    },
    {
      id: `de_vertigo`,
      label: 'de_vertigo',
    },
    {
      id: `de_inferno`,
      label: 'de_inferno',
    },
    {
      id: `de_overpass`,
      label: 'de_overpass',
    },
    {
      id: `de_nuke`,
      label: 'de_nuke',
    },
    {
      id: `de_train`,
      label: 'de_train',
    },
  ];
  types = [
    {
      id: `SMOKE`,
      label: 'Smoke',
    },
    {
      id: `MOLLY`,
      label: 'Incendiary',
    },
    {
      id: `FLASH`,
      label: 'Flash',
    },
    {
      id: `HE`,
      label: 'High Explosive',
    },
  ];
  sides = [
    {
      id: `CT`,
      label: 'CT',
    },
    {
      id: `B`,
      label: 'Both',
    },
    {
      id: `T`,
      label: 'T',
    },
  ];
  timing = [
    {
      id: `pre`,
      label: 'Pre-Plant',
    },
    {
      id: `both`,
      label: 'Both',
    },
    {
      id: `after`,
      label: 'After-Plant',
    },
  ];

  render() {
     /*return (
      <div>
        <NadeTypeSelect
        type={this.props.type}
        selectType={this.onSetType} />
        
      </div>); */

      return (
        <div>
          <EuiAccordion
      id="accordion9"
      buttonContent="Map"
      initialIsOpen
      paddingSize="s">

            <EuiRadioGroup
        options={this.maps}
        idSelected={this.props.map}
        onChange={(id) => this.onSetMap(id)}
        name="map"/>
          </EuiAccordion>
          <EuiAccordion
      id="accordion9"
      initialIsOpen
      buttonContent="Type"
      paddingSize="s">

            <EuiRadioGroup
        options={this.types}
        idSelected={this.props.type}
        onChange={(id) => this.onSetType(id)}
        name="type"/>
          </EuiAccordion>

          <EuiAccordion
      id="accordion10"
      buttonContent="Filters"
      initialIsOpen
      paddingSize="s">

            <EuiDualRange
      id="airtime"
      min={0}
      max={10}
      step={0.1}
      value={this.props.airtime}
      onChange={(id) => this.onSetAirtime(id)}
      showLabels
      aria-label="Airtime"/>

            <EuiButtonGroup
        legend="Used by"
        options={this.sides}
        value={this.props.sides}
        onChange={(id) => this.onSetSide(id)}/>
            <EuiButtonGroup
        legend="Used by"
        options={this.timing}
        value={this.props.timing}
        onChange={(id) => this.onSetTiming(id)}/>

          </EuiAccordion>




   
        </div>
      /*
      <Accordion>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Type
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <RadioGroup onChange={this.onSetType} value={this.props.type}>
              <Stack direction="row">
                <Radio value="SMOKE">Smokes</Radio>
                <Radio value="MOLLY">Mollies/Incen</Radio>
                <Radio value="HE">High Explosives</Radio>
                <Radio value="FLASH">Flashes</Radio>
              </Stack>
            </RadioGroup>
          </AccordionPanel>
        </AccordionItem>
      
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Section 2 title
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>*/);
  }
}
/*<NadeMapSelect
        map={this.props.map}
        selectMap={this.onSetMap} />*/
export default Filters;
