import * as data from '../../../../static/clustered/nades_live.json';
var _ = require('lodash/core');

class Datasource{
    constructor(initial) {
        this.nades = data.default.nades;
        this.maps = data.default.maps;
        this.teams = data.default.teams;
        this.filters = {
            map: 'de_mirage',
            type: 'SMOKE',
            clusterId: -1,
            nadeId: -1,
            vars: {

            }

        }
        this.selection = undefined;
        this.filter(initial);
    }
    filter(newFilters){
        let old = this.filters;
        Object.assign(this.filters, newFilters);
        //if(!_.isEqual(old, this.filters)){
            this.selection = this.applyFilter();
        //}
    }

    applyFilter(){
        let f = this.filters;
        //get nades by type & filter
        let nades = this.nades[f.map] && this.nades[f.map][f.type] || {};

        //check for clusterId
        if(f.clusterId != -1){
            nades =  {0: nades[f.clusterId] || {} }
        }
        //filter items
        Object.entries(nades).forEach(([clusterKey, cluster]) => {
            Object.entries(cluster.nades).forEach(([nadeKey, nade]) => {
                if(!this.filterHelper(f, nade)){
                    delete nades[clusterKey].nades[nadeKey]
                }
             });
             if(Object.keys(cluster.nades).length == 0 && clusterKey != 0){
                delete nades[clusterKey];
             }
         });

         return nades;
    }
    operator_table = {
        '>': function(a, b) { return a > b; },
        '=>': function(a, b) { return a => b; },
        '<=': function(a, b) { return a <= b; },
        '==': function(a, b) { return a == b; },
        '!=': function(a, b) { return a != b; },
        
    };
    checkEntry(obj, path, comp, value){
        return this.operator_table[comp](this.byPath(obj, path), value)
    }
    byPath(obj, path) {
        var paths = path.split('.')
          , current = obj
          , i;
      
        for (i = 0; i < paths.length; ++i) {
          if (current[paths[i]] == undefined) {
            return undefined;
          } else {
            current = current[paths[i]];
          }
        }
        return current;
      }
      

    filterHelper(f, nade){
        let keep = true;
        Object.entries(f.vars).forEach(([compKey, v]) => {
            if(!this.checkEntry(nade, v.path, v.comp, v.value)){
                keep = false;
                return;
            }
         });
         return keep;
    }
    



    getClusters(){
        return this.selection;
    }
    getNades(){
        if(this.filters.clusterId == -1) return {};
        return this.getClusters()[0].nades
    }
    getNade(nadeId){
        return  this.nades[this.filters.map][this.filters.type][this.filters.clusterId].nades[nadeId] || {};
    }
    
}

export default Datasource;