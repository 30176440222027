import React, { Fragment }  from 'react';
import {  EuiToolTip, EuiFieldText, EuiButtonIcon,EuiCopy, EuiSpacer } from '@elastic/eui';
const CopyField  = (props) => {
  return (
    <EuiCopy textToCopy={props.value} display="block">
      {(copy) => (
        <EuiToolTip content={props.tooltip}  display="block">
          
          <EuiFieldText
        value={props.value}
        append={

          <EuiButtonIcon iconType="copyClipboard" onClick={copy} aria-label="Copy Value" />
 
        }
        readOnly
        fullWidth/>
        </EuiToolTip>
    )}
    </EuiCopy>
  );
};
export default CopyField;