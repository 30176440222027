import React from 'react';
import style from './mapItem.css';
import Map from '../../static/maps'
import cloud from './cloud.png'
import flash from './flash.png'
import fire from './fire.png'
import start from './startpoint.png'

class MapItem extends React.Component {
  constructor(props) {
    super(props);
    this.map = new Map(this.props.nade.map);

  }
  
  
  styleForType = type => {
    switch(type){
      case "SMOKE":
        return [cloud, style.smoke];
      break;
      case "MOLLY":
        return [fire, style.fire];
      break;
      case "HE":
        return [cloud, style.smoke];
      break;
      case "FLASH":
        return [flash, style.flash];
      break;
      case "START":
        return [start, style.start];
      break;


    }
  }

  onSelected = event => {
    this.props.onSelected(this.props.nade)
  };

  calcStyle = (pos, map) => {
    let [x,y] = this.map.translateToPercent(pos.x, pos.y, map);
    return {
      left: x+"%",
      top: -y+"%"
  }
  }


  render() {
    let [img, nadeStyle] = this.styleForType(this.props.type);
    return (
      <div  onClick={this.props.onClick} className={style.nade+' '+nadeStyle} style={this.calcStyle(this.props.pos, this.props.nade.map)}>
        <img src={img} ></img>

      </div>);
  }
}

export default MapItem;
