import React from 'react';
import PropTypes from 'prop-types';
import style from './map.css';
import Filters from '../filters/';
import Visualizer from '../visualizer'
import Routes from  '../../static/routes'
import Datasource from '../../static/datasource'

import {
  EuiPageTemplate,
  EuiButton,
  EuiFlexGrid,
  EuiFlexItem,
  EuiPanel,
  EuiFlyoutHeader,
  EuiTitle,
  EuiFlyoutBody,
  EuiFlyout,
} from '@elastic/eui';
import { EuiSideNav } from '@elastic/eui';
import NadeView from '../nadeView';

class MapView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: props.match.params.map,
      type: props.match.params.type,
      clusterId: (props.match.params.clusterId?props.match.params.clusterId:-1),
      nadeId: (props.match.params.nadeId?props.match.params.nadeId:-1),
      airtime: 0
    };
    this.data = new Datasource(this.state);
  }
  componentDidUpdate(prevProps){
    this.data.filter(this.state);

  }
  updateNadePath = () => {
    Routes.set(this, Routes.createNadePath(this.state.map, this.state.type, this.state.clusterId, this.state.nadeId));
  }

  setFilterState = event => {
    event = Object.assign({clusterId: -1, nadeId: -1}, event);
    this.setState(event,this.updateNadePath);
  };
  setSelectionState = event => {
    this.setState(event,this.updateNadePath);
  }

    //
    handleSliderChange = (event) => {
      //setTabIndex(parseInt(event.target.value, 10))
    }

    unselectNade = () =>{
      this.setState({nadeId: -1});
    }
    getSelectedNade = () => {
      if(!this.nadeVisible()) return null;
      return this.data.getNade(this.state.nadeId)
    }
    nadeVisible = () => {
      return  !(this.state.clusterId == -1 || this.state.nadeId == -1);
    }
    flyout = () => {
     if(this.nadeVisible()){
      return(<EuiFlyout
          ownFocus
          onClose={() => this.unselectNade()}
          size="l"
          aria-labelledby="flyoutTitle">
        <EuiFlyoutBody>
          <NadeView nade={this.getSelectedNade()}  />
        </EuiFlyoutBody>
      </EuiFlyout>);
    }
  }


  render() {

    return (
      <EuiPageTemplate
      template="centeredContent"
      pageContentProps={{ paddingSize: 'none' }}
      pageSideBar={<Filters
        map={this.state.map}
        type={this.state.type}
      setFilterState={this.setFilterState} />}
      pageHeader={undefined}>
        <Visualizer
              map={this.state.map}
              type={this.state.type}
              nades = {this.data.getNades()}
              clusters = {this.data.getClusters()}
              setSelectionState={this.setSelectionState}/>
        {this.flyout()}
      </EuiPageTemplate>
);
  }
}
/**/

export default MapView;
